import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useApolloClient } from 'hooks';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect } from 'react';
import { Footer, Navbar, ThemeTypeProvider } from 'templates/layout';
import { ToastContainer } from 'react-toastify';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/global.css';

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const apolloClient = useApolloClient(pageProps.initialApolloState);
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <title>US Software Solutions</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=0" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ThemeTypeProvider>
          <CssBaseline />
          <ToastContainer />
          <Navbar />
          <Component {...pageProps} />
          <Footer />
        </ThemeTypeProvider>
      </ApolloProvider>
    </>
  );
};

export default MyApp;
